import React from 'react';
import { Provider } from '@components/atoms/Provider/Provider';

export interface WatchLinkProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link: any;
  color?: 'default' | 'white';
}

export const WatchLink = ({ link, color = 'default' }: WatchLinkProps) => {
  if (!link?.providerEntry) return;

  const currencies = {
    EUR: '€',
    USD: '$',
  };

  const text =
    (link.pricing === 'rent'
      ? 'Leihen ab ' + currencies[link.currency || 'EUR']
      : link.pricing === 'buy'
        ? 'Kaufen ab ' + currencies[link.currency || 'EUR']
        : 'ansehen') + ` ${link.price || ''}`;

  return (
    <Provider
      link={link.link}
      provider={link.providerEntry}
      providerSlug={link.provider}
      style="button"
      text={text}
      color={color}
      featured={link.providerEntry.sponsoring}
    />
  );
};
