import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Avatar } from '@components/atoms/Avatar/Avatar';
import { Link } from '@components/atoms/Link/Link';
import { GetMoviePersonsQuery } from '@lib/graphql/generated';
import { IconArrow } from '@assets/icon-arrow';

export interface PersonProps extends PropsWithClassProps {
  person: GetMoviePersonsQuery['movie']['persons']['data'][0];
  type?: 'compact' | 'medium';
}

export const Person = React.forwardRef<HTMLDivElement, PersonProps>(({ person, type, classProps }, ref) => {
  return type === 'compact' ? (
    <Link
      href={'/personen/' + (person.slug || person.id)}
      classProps={{
        root: twMerge(
          'grid group min-w-0 max-w-full cursor-pointer grid-cols-[auto,1fr] items-center gap-4 rounded-xl streaming-entry-bg border-0.5 border-primary/0 transition-colors duration-150 hover:border-primary p-4',
          classProps?.root,
        ),
      }}
    >
      <div className="z-10 size-12 overflow-hidden rounded-full">
        {person.image?.file ? (
          <Avatar
            avatar={person.image.file}
            alt={'Foto von ' + person.name}
            classProps={{
              root: 'w-full h-full pointer:group-hover:scale-105 z-0 pointer:transition-transform pointer:duration-300',
            }}
          />
        ) : (
          <div className="aspect-square w-12 rounded-full bg-gray-700"></div>
        )}
      </div>
      <div className="grid w-full min-w-0 max-w-full grid-cols-[auto,1fr] justify-start gap-2 text-white">
        <div className="line-clamp-1 max-w-full break-all text-base font-bold">{person.name}</div>
        <div className="flex">
          <div className="w-0 transition-all duration-200 group-hover:w-full"></div>
          <IconArrow
            classProps={{
              root: 'inline h-2 my-auto flex-none transition-all duration-300 group-hover:text-primary',
            }}
          />
        </div>
      </div>
    </Link>
  ) : (
    <div
      ref={ref}
      className={twMerge(
        'group grid min-h-60 w-52 items-center justify-center rounded-xl border-0.5 border-primary/0 bg-gradient-to-t from-gray-820 to-gray-700 px-10 py-6 text-center transition-colors duration-150 hover:border-primary',
        classProps?.root,
      )}
    >
      <Link href={'/personen/' + (person.slug || person.id)}>
        <div className="z-10 mx-auto inline-block size-32 overflow-hidden rounded-full">
          {person.image?.file ? (
            <Avatar
              avatar={person.image.file}
              alt={'Foto von ' + person.name}
              classProps={{
                root: 'w-full h-full pointer:group-hover:scale-105 z-0 pointer:transition-transform pointer:duration-300',
              }}
            />
          ) : (
            <div className="size-full bg-gray-300"></div>
          )}
        </div>
        <div className="font-herokid text-xl text-white">{person.name}</div>
        {(person.meta?.rolenames.length > 0 || person.meta?.positions.length > 0) && (
          <div className="text-2xs font-bold uppercase tracking-2px text-gray-500">
            {person.meta.rolenames.join(', ') || person.meta.positions?.join(', ')}
          </div>
        )}
      </Link>
    </div>
  );
});

Person.displayName = 'Person';
