import React, { PropsWithChildren } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Title } from '@components/atoms/Title';

interface EntitySectionTitleProps extends PropsWithChildren, PropsWithClassProps {
  heading?: boolean;
}

export const EntitySectionTitle = ({ heading, classProps, children }: EntitySectionTitleProps) => {
  return heading ? (
    <Title
      level={2}
      classProps={{
        heading: twMerge(
          'tracking-widest text-sm mt-6 lg:text-sm text-gray-820 dark:text-gray-500 font-proxima-nova mb-2 font-semibold uppercase',
          classProps?.root,
        ),
      }}
    >
      {children}
    </Title>
  ) : (
    <div
      className={twMerge(
        'mb-2 mt-6 font-proxima-nova text-sm font-semibold uppercase tracking-widest text-gray-820 dark:text-gray-500 lg:text-sm',
        classProps?.root,
      )}
    >
      {children}
    </div>
  );
};
