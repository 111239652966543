import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export interface EntityScoreProps extends PropsWithClassProps<'item'> {
  score: number;
  max: number;
  target?: number;
}

export const EntityScore = ({ score, max, target = 4, classProps }: EntityScoreProps) => {
  const calculatedScore = Math.floor((score / max) * target);
  const itemStyle = twMerge('bg-white w-5 h-5 rounded-full', classProps?.item);
  const rootStyle = twMerge('mb-3 flex gap-1', classProps?.root);

  return (
    <div className={rootStyle}>
      {[...Array(target)].map((e, index) => (
        <div key={index} className={twMerge(itemStyle, index >= calculatedScore && 'bg-white/20')}></div>
      ))}
    </div>
  );
};
