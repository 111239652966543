import React, { PropsWithChildren } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export interface EntityInfoProps extends PropsWithChildren, PropsWithClassProps<'value' | 'label' | 'unit'> {
  value?: string | number;
  label: string;
  unit?: string;
}

export const EntityInfo = ({ value, label, unit, children, classProps }: EntityInfoProps) => {
  if (!value && !children) return;

  const rootStyle = twMerge('text-center inline-block', classProps?.root);
  const valueStyle = twMerge('font-herokid text-2xl font-semibold', classProps?.value);
  const labelStyle = twMerge(
    'mt-1 text-3xs font-bold uppercase tracking-2px leading-smaller opacity-80',
    classProps?.label,
  );
  const unitStyle = twMerge('ml-1 font-semibold uppercase tracking-widest', classProps?.unit);
  return (
    <div className={rootStyle}>
      {children || (
        <div>
          <span className={valueStyle}>{value}</span>
          {unit && <span className={unitStyle}>{unit}</span>}
        </div>
      )}
      <div className={labelStyle}>{label}</div>
    </div>
  );
};
