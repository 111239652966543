import React from 'react';
import { Image, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Asset } from '@lib/schema';

export interface AvatarProps extends PropsWithClassProps<'avatar'> {
  avatar: Partial<Asset>;
  width?: string;
  height?: string;
  alt?: string;
}

export const Avatar = ({ avatar, alt, classProps }: AvatarProps) => {
  const containerStyle = twMerge('relative w-12 h-12 rounded-full overflow-hidden', classProps?.root);
  return (
    <div className={containerStyle}>
      {avatar?.path && (
        <Image
          src={avatar.path}
          alt={alt || avatar.alt || ''}
          copyright=""
          fill
          classProps={{ container: 'rounded-full', root: 'object-cover' }}
          sizes="180px"
        />
      )}
    </div>
  );
};
